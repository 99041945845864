// types
import { createSlice } from '@reduxjs/toolkit';
import api from '../../api';

// initial state
const initialState = {
  openItem: ['dashboard'],
  openComponent: 'buttons',
  drawerOpen: false,
  componentDrawerOpen: true
};

// ==============================|| SLICE - MENU ||============================== //

const menu = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    activeItem(state, action) {
      state.openItem = action.payload.openItem;
    },

    activeComponent(state, action) {
      state.openComponent = action.payload.openComponent;
    },

    openDrawer(state, action) {
      state.drawerOpen = action.payload.drawerOpen;
    },

    openComponentDrawer(state, action) {
      state.componentDrawerOpen = action.payload.componentDrawerOpen;
    },
    setErrors(state, action) {
      state.componentDrawerOpen = action.payload.componentDrawerOpen;
    }
  }
});

export default menu.reducer;

export const MenuActions = menu.actions;
export const getCurrencies = (query, params) => async (dispatch) => {
  // dispatch(menuActions.activeItem(true));
  // dispatch(menuActions.setErrors([]));
  try {
    // const res = await api.get('posts?page=1&pageSize=3&title=&sort=DESC');
    // if (res.status === 200 && res?.data) {
    //     dispatch(currenciesActions.loadCurrencies(res.data));
    // }
  } catch (authErrors) {
    // dispatch(currenciesActions.setErrors(Array.isArray(authErrors) ? authErrors : [authErrors]));
  } finally {
    // dispatch(currenciesActions.setLoading(false));
  }
};
