// third-party
import { combineReducers } from 'redux';

// project import
import menu from './menu';
import posts from './posts';
import events from './events';
import fundraisers from './fundraisers';
import auth from './auth';
import subcategories from './subcategories';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({ menu, posts, events, fundraisers, auth, subcategories });

export default reducers;
