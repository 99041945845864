const configs = {
  apiUrl: ''
};

if (process.env.NODE_ENV === 'production') {
  configs.apiUrl = process.env.REACT_APP_BASE_URL;
} else {
  configs.apiUrl = process.env.REACT_APP_BASE_URL;
}

export { configs };
