import React from 'react';
import { createSlice } from '@reduxjs/toolkit';
import api from '../../api';
import { Navigate } from 'react-router';

// initial state
const initialState = {
  loading: false,
  auth: [],
  authCount: 0,
  errors: undefined
};

// ==============================|| SLICE - EVENTS ||============================== //

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoading(state, action) {
      return {
        ...state,
        loading: action.payload
      };
    },
    getAuth(state, action) {
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token
      };
    },
    setErrors(state, action) {
      return {
        ...state,
        errors: action.payload
      };
    }
  }
});

export default auth.reducer;

export const PostActions = auth.actions;
export const authLogin = ({ email, password, keepSign }, navigate) => {
  return async (dispatch) => {
    dispatch(PostActions.setLoading(true));
    dispatch(PostActions.setErrors([]));
    try {
      const res = await api.post('auth/sign-in', {
        email: email,
        password: password
      });
      if (res.status === 200 && res?.data) {
        dispatch(PostActions.getAuth(res.data));
        if (keepSign) {
          localStorage.setItem('token', res.data.token);
          localStorage.setItem('user', JSON.stringify(res.data.user));
        } else {
          sessionStorage.setItem('token', res.data.token);
          sessionStorage.setItem('user', JSON.stringify(res.data.user));
        }
        navigate('/');
      }
    } catch (authErrors) {
      // dispatch(PostActions.setErrors(Array.isArray(authErrors) ? authErrors : [authErrors]));
    } finally {
      dispatch(PostActions.setLoading(false));
    }
  };
};
export const authLogOut = (navigate) => {
  return async (dispatch) => {
    dispatch(PostActions.setLoading(true));
    dispatch(PostActions.setErrors([]));
    try {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('user');
      navigate('/login');
    } catch (authErrors) {
      // dispatch(PostActions.setErrors(Array.isArray(authErrors) ? authErrors : [authErrors]));
    } finally {
      dispatch(PostActions.setLoading(false));
    }
  };
};
export const selectAuthData = (state) => state.auth.user;
