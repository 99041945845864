import base from 'axios';
import { configs } from './app.config';

const api = base.create({
  baseURL: configs.apiUrl
});
api.interceptors.request.use(
  (config) => {
    const newConfig = config;
    // const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en';
    //
    // newConfig.headers['lang'] = lang;
    if (localStorage.token || sessionStorage.token) {
      newConfig.headers.Authorization = `${
        sessionStorage.token ? sessionStorage : localStorage.token
      }`;
    }
    return newConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default api;
