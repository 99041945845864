import { createSlice } from '@reduxjs/toolkit';
import api from '../../api';

const initialState = {
  events: [],
  errors: null,
  loading: false,
  eventsCount: 0,
  singleEvent: {}
};

const events = createSlice({
  name: 'events',
  initialState,
  reducers: {
    setLoading(state, { payload }) {
      state.loading = payload;
    },
    getEvents(state, { payload }) {
      state.events = payload.rows;
      state.eventsCount = payload.count;
    },
    getEventById(state, { payload }) {
      state.singleEvent = payload;
    },
    invalidateSingleEvent(state) {
      state.singleEvent = {};
    },
    setErrors(state, { payload }) {
      state.errors = payload;
    }
  }
});

export default events.reducer;

export const { setLoading, getEvents, getEventById, invalidateSingleEvent, setErrors } =
  events.actions;

export const fetchEvents = (query, params) => async (dispatch) => {
  dispatch(setLoading(true));
  dispatch(setErrors([]));
  try {
    const res = await api.get('events', {
      params: params
    });
    if (res.status === 200 && res?.data) {
      dispatch(getEvents(res.data));
    }
  } catch (authErrors) {
    dispatch(setErrors(Array.isArray(authErrors) ? authErrors : [authErrors]));
  } finally {
    dispatch(setLoading(false));
  }
};

export const fetchSingleEvent = (query) => async (dispatch) => {
  dispatch(setLoading(true));
  dispatch(setErrors([]));
  try {
    const res = await api.get(`events/${query.uuid}`);
    if (res.status === 200 && res?.data) {
      dispatch(getEventById(res.data));
    }
  } catch (authErrors) {
    dispatch(setErrors(Array.isArray(authErrors) ? authErrors : [authErrors]));
  } finally {
    dispatch(setLoading(false));
  }
};

export const createEvent = (query, callback) => async (dispatch) => {
  dispatch(setLoading(true));
  dispatch(setErrors([]));
  try {
    const res = await api.post(`events`, query);
    if (res.status === 200 && res?.data) {
      dispatch(getEvents({}, { page: 1, pageSize: 10, title: '', sort: 'DESC' }));
      callback();
    }
  } catch (authErrors) {
    dispatch(setErrors(Array.isArray(authErrors) ? authErrors : [authErrors]));
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateEvent = (query, params) => async (dispatch) => {
  dispatch(setLoading(true));
  dispatch(setErrors([]));
  try {
    const res = await api.put(`events/${params.uuid}`, query);
    if (res.status === 200 && res?.data) {
      // TODO: Changes required
      console.log(res?.data);
    }
  } catch (authErrors) {
    dispatch(setErrors(Array.isArray(authErrors) ? authErrors : [authErrors]));
  } finally {
    dispatch(setLoading(false));
  }
};

export const deleteEvent = (query) => async (dispatch) => {
  dispatch(setLoading(true));
  dispatch(setErrors([]));
  try {
    const res = await api.delete(`events/${query.uuid}`);
    if (res.status === 204) {
      dispatch(getEvents({}, { page: 1, pageSize: 10, title: '', sort: 'DESC' }));
    }
  } catch (authErrors) {
    dispatch(setErrors(Array.isArray(authErrors) ? authErrors : [authErrors]));
  } finally {
    dispatch(setLoading(false));
  }
};
