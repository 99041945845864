import { lazy } from 'react';
import Loadable from '../components/Loadable';

const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));

const AuthLogin = Loadable(lazy(() => import('pages/authentication/Login')));

const PostsPage = Loadable(lazy(() => import('pages/posts/index')));
const AddPostPage = Loadable(lazy(() => import('pages/posts/addPost')));
const EditPostPage = Loadable(lazy(() => import('pages/posts/editPost')));
const EventsPage = Loadable(lazy(() => import('pages/events')));
const AddEventPage = Loadable(lazy(() => import('pages/events/addEvent')));
const EditEventPage = Loadable(lazy(() => import('pages/events/editEvent')));
const FundraisersPage = Loadable(lazy(() => import('pages/fundraisers')));
const EditFundraiserPage = Loadable(lazy(() => import('pages/fundraisers/editFundriseing')));
const AddFundraisingPage = Loadable(lazy(() => import('pages/fundraisers/addFundraiseing')));
const SubcategoriesPage = Loadable(lazy(() => import('pages/subcategories/index')));
const AddSubcategoryPage = Loadable(lazy(() => import('pages/subcategories/addSubcategory')));
const EditSubcategoryPage = Loadable(lazy(() => import('pages/subcategories/editSubcategory')));

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    Component: DashboardDefault,
    exact: true,
    private: true
  },
  {
    path: '/login',
    name: 'Login',
    Component: AuthLogin,
    exact: true,
    private: false
  },
  {
    path: '/posts',
    name: 'Posts',
    Component: PostsPage,
    exact: true,
    private: true
  },
  {
    path: '/posts/:uuid/edit',
    name: 'Post edit',
    Component: EditPostPage,
    exact: true,
    private: true
  },
  {
    path: '/posts/add',
    name: 'Add Post',
    Component: AddPostPage,
    exact: true,
    private: true
  },
  {
    path: '/events',
    name: 'Add Events',
    Component: EventsPage,
    exact: true,
    private: true
  },
  {
    path: '/events/add',
    name: 'Add Event',
    Component: AddEventPage,
    exact: true,
    private: true
  },
  {
    path: '/events/:uuid/edit',
    name: 'Event edit',
    Component: EditEventPage,
    exact: true,
    private: true
  },
  {
    path: '/fundraisers',
    name: 'Fundraisers',
    Component: FundraisersPage,
    exact: true,
    private: true
  },
  {
    path: '/fundraisers/:uuid/edit',
    name: 'Event edit',
    Component: EditFundraiserPage,
    exact: true,
    private: true
  },
  {
    path: '/fundraisers/add',
    name: 'Add Fundraising',
    Component: AddFundraisingPage,
    exact: true,
    private: true
  },
  {
    path: '/subcategories',
    name: 'Subcategories',
    Component: SubcategoriesPage,
    exact: true,
    private: true
  },
  {
    path: '/subcategories/:uuid/edit',
    name: 'Event edit',
    Component: EditSubcategoryPage,
    exact: true,
    private: true
  },
  {
    path: '/subcategories/add',
    name: 'Add Subcategories',
    Component: AddSubcategoryPage,
    exact: true,
    private: true
  }
];
export default routes;
