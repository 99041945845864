import { createSlice } from '@reduxjs/toolkit';

import api from '../../api';

const initialState = {
  loading: false,
  singleSubcategory: {},
  subcategories: [],
  errors: null
};

const subcategories = createSlice({
  name: 'subcategories',
  initialState,
  reducers: {
    setLoading(state, { payload }) {
      state.loading = payload;
    },
    getSubcategories(state, { payload }) {
      state.subcategories = payload;
    },
    addSubcategory(state, { payload }) {
      state.subcategories = payload.rows;
    },
    getSubcategoryById(state, { payload }) {
      state.singleSubcategory = payload;
    },
    invalidateSingleSubcategory(state) {
      state.singleSubcategory = {};
    },
    setErrors(state, { payload }) {
      state.errors = payload;
    }
  }
});

export default subcategories.reducer;

export const {
  setLoading,
  getSubcategories,
  addSubcategory,
  getSubcategoryById,
  setErrors,
  invalidateSingleSubcategory
} = subcategories.actions;

export const fetchSubcategories = (query, params) => async (dispatch) => {
  dispatch(setLoading(true));
  dispatch(setErrors([]));
  try {
    const res = await api.get('subcategories', {
      params: params
    });
    if (res.status === 200 && res?.data) {
      dispatch(getSubcategories(res.data));
    }
  } catch (authErrors) {
    dispatch(setErrors(Array.isArray(authErrors) ? authErrors : [authErrors]));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getSingleSubcategory = (query, params) => async (dispatch) => {
  dispatch(setLoading(true));
  dispatch(setErrors([]));
  try {
    const res = await api.get(`subcategories/${query.uuid}`);
    if (res.status === 200 && res?.data) {
      dispatch(getSubcategoryById(res.data));
    }
  } catch (authErrors) {
    dispatch(setErrors(Array.isArray(authErrors) ? authErrors : [authErrors]));
  } finally {
    dispatch(setLoading(false));
  }
};
export const updateSubcategory = (query, params) => async (dispatch) => {
  dispatch(setLoading(true));
  dispatch(setErrors([]));
  try {
    const res = await api.put(`subcategories/${params.uuid}`, query);
    if (res.status === 200 && res?.data) {
      // TODO: Changes required
      console.log(res?.data);
    }
  } catch (authErrors) {
    dispatch(setErrors(Array.isArray(authErrors) ? authErrors : [authErrors]));
  } finally {
    dispatch(setLoading(false));
  }
};
export const createSubcategory = (params, navigation) => async (dispatch) => {
  dispatch(setLoading(true));
  dispatch(setErrors([]));
  try {
    const res = await api.post(`subcategories`, params);
    if (res.status === 200 && res?.data) {
      dispatch(addSubcategory(res.data));
      navigation('/subcategories', { replace: true });
    }
  } catch (authErrors) {
    dispatch(setErrors(Array.isArray(authErrors) ? authErrors : [authErrors]));
  } finally {
    dispatch(setLoading(false));
  }
};
export const deleteSubcategory = (query) => async (dispatch) => {
  dispatch(setLoading(true));
  dispatch(setErrors([]));
  try {
    const res = await api.delete(`subcategories/${query.uuid}`);
    if (res.status === 204) {
      dispatch(fetchSubcategories({}, { page: 1, pageSize: 10, title: '', sort: 'DESC' }));
    }
  } catch (authErrors) {
    dispatch(setErrors(Array.isArray(authErrors) ? authErrors : [authErrors]));
  } finally {
    dispatch(setLoading(false));
  }
};
