import { createSlice } from '@reduxjs/toolkit';
import api from '../../api';

const initialState = {
  errors: null,
  loading: false,
  fundraisers: [],
  fundraisersCount: 0,
  singleFundraiser: {}
};

const fundraisers = createSlice({
  name: 'fundraisers',
  initialState,
  reducers: {
    setLoading(state, { payload }) {
      state.loading = payload;
    },
    getFundraisers(state, { payload }) {
      state.fundraisers = payload.rows;
      state.fundraisersCount = payload.count;
    },
    getFundraiserById(state, { payload }) {
      state.singleFundraiser = payload;
    },
    addFundraiser(state, { payload }) {
      state.fundraiser = payload;
    },
    invalidateSingleFundraiser(state) {
      state.singleFundraiser = {};
    },
    setErrors(state, { payload }) {
      state.errors = payload;
    }
  }
});

export default fundraisers.reducer;

export const {
  setLoading,
  getFundraisers,
  getFundraiserById,
  addFundraiser,
  invalidateSingleFundraiser,
  setErrors
} = fundraisers.actions;

export const fetchFundraisers = (query, params) => async (dispatch) => {
  dispatch(setLoading(true));
  dispatch(setErrors([]));
  try {
    const res = await api.get('fundraisers', {
      params: params
    });
    if (res.status === 200 && res?.data) {
      dispatch(getFundraisers(res.data));
    }
  } catch (authErrors) {
    dispatch(setErrors(Array.isArray(authErrors) ? authErrors : [authErrors]));
  } finally {
    dispatch(setLoading(false));
  }
};
export const fetchSingleFundraiser = (query) => async (dispatch) => {
  dispatch(setLoading(true));
  dispatch(setErrors([]));
  try {
    const res = await api.get(`fundraisers/${query.uuid}`);
    if (res.status === 200 && res?.data) {
      dispatch(getFundraiserById(res.data));
    }
  } catch (authErrors) {
    dispatch(setErrors(Array.isArray(authErrors) ? authErrors : [authErrors]));
  } finally {
    dispatch(setLoading(false));
  }
};

export const createFundraiser = (query) => async (dispatch) => {
  dispatch(setLoading(true));
  dispatch(setErrors([]));
  try {
    const res = await api.post(`fundraisers`, query);
    if (res.status === 200 && res?.data) {
      dispatch(addFundraiser(res.data));
    }
  } catch (authErrors) {
    dispatch(setErrors(Array.isArray(authErrors) ? authErrors : [authErrors]));
  } finally {
    dispatch(setLoading(false));
  }
};
export const updateFundraiser = (query, params) => async (dispatch) => {
  dispatch(setLoading(true));
  dispatch(setErrors([]));
  try {
    const res = await api.put(`fundraisers/${params.uuid}`, query);
    if (res.status === 200 && res?.data) {
      // TODO: Changes required
      console.log(res?.data);
    }
  } catch (authErrors) {
    dispatch(setErrors(Array.isArray(authErrors) ? authErrors : [authErrors]));
  } finally {
    dispatch(setLoading(false));
  }
};
export const deleteFundraiser = (query) => async (dispatch) => {
  dispatch(setLoading(true));
  dispatch(setErrors([]));
  try {
    const res = await api.delete(`fundraisers/${query.uuid}`);
    if (res.status === 204) {
      dispatch(getFundraisers({}, { page: 1, pageSize: 10, title: '', sort: 'DESC' }));
    }
  } catch (authErrors) {
    dispatch(setErrors(Array.isArray(authErrors) ? authErrors : [authErrors]));
  } finally {
    dispatch(setLoading(false));
  }
};
