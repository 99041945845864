import { createSlice } from '@reduxjs/toolkit';

import api from '../../api';

const initialState = {
  loading: false,
  posts: [],
  singlePost: {},
  postsCount: 0,
  errors: null
};

const posts = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    setLoading(state, { payload }) {
      state.loading = payload;
    },
    getPosts(state, { payload }) {
      state.posts = payload.rows;
      state.postsCount = payload.count;
    },
    addPost(state, { payload }) {
      state.posts = payload.rows;
      state.postsCount = payload.count;
    },
    getPostById(state, { payload }) {
      state.singlePost = payload;
    },
    invalidateSinglePost(state) {
      state.singlePost = {};
    },
    setErrors(state, { payload }) {
      state.errors = payload;
    }
  }
});

export default posts.reducer;

export const { setLoading, getPosts, addPost, getPostById, invalidateSinglePost, setErrors } =
  posts.actions;
export const fetchPosts = (query, params) => async (dispatch) => {
  dispatch(setLoading(true));
  dispatch(setErrors([]));
  try {
    const res = await api.get('posts', {
      params: params
    });
    if (res.status === 200 && res?.data) {
      dispatch(getPosts(res.data));
    }
  } catch (authErrors) {
    dispatch(setErrors(Array.isArray(authErrors) ? authErrors : [authErrors]));
  } finally {
    dispatch(setLoading(false));
  }
};

export const fetchSinglePost = (query) => async (dispatch) => {
  dispatch(setLoading(true));
  dispatch(setErrors([]));
  try {
    const res = await api.get(`posts/${query.uuid}`);
    if (res.status === 200 && res?.data) {
      dispatch(getPostById(res.data));
    }
  } catch (authErrors) {
    dispatch(setErrors(Array.isArray(authErrors) ? authErrors : [authErrors]));
  } finally {
    dispatch(setLoading(false));
  }
};
export const createPost = (query) => async (dispatch) => {
  dispatch(setLoading(true));
  dispatch(setErrors([]));
  try {
    const res = await api.post(`posts`, query);
    if (res.status === 200 && res?.data) {
      dispatch(addPost(res.data));
    }
  } catch (authErrors) {
    dispatch(setErrors(Array.isArray(authErrors) ? authErrors : [authErrors]));
  } finally {
    dispatch(setLoading(false));
  }
};
export const updateSinglePost = (query, params) => async (dispatch) => {
  dispatch(setLoading(true));
  dispatch(setErrors([]));
  try {
    const res = await api.put(`posts/${params.uuid}`, query);
    if (res.status === 200 && res?.data) {
      // TODO: Changes required
      console.log(res?.data);
    }
  } catch (authErrors) {
    dispatch(setErrors(Array.isArray(authErrors) ? authErrors : [authErrors]));
  } finally {
    dispatch(setLoading(false));
  }
};
export const deletePost = (query) => async (dispatch) => {
  dispatch(setLoading(true));
  dispatch(setErrors([]));
  try {
    const res = await api.delete(`posts/${query.uuid}`);
    if (res.status === 204) {
      dispatch(fetchPosts({}, { page: 1, pageSize: 10, title: '', sort: 'DESC' }));
    }
  } catch (authErrors) {
    dispatch(setErrors(Array.isArray(authErrors) ? authErrors : [authErrors]));
  } finally {
    dispatch(setLoading(false));
  }
};
