// assets
import { LoginOutlined, ProfileOutlined, TableOutlined } from '@ant-design/icons';

// icons
const icons = {
  LoginOutlined,
  ProfileOutlined,
  TableOutlined
};

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const pages = {
  id: 'pages',
  title: 'Pages',
  type: 'group',
  children: [
    {
      id: 'posts',
      title: 'Posts',
      type: 'item',
      url: '/posts',
      icon: icons.TableOutlined
    },
    {
      id: 'events',
      title: 'Events',
      type: 'item',
      url: '/events',
      icon: icons.TableOutlined
    },
    {
      id: 'fundraisers',
      title: 'Fundraisers',
      type: 'item',
      url: '/fundraisers',
      icon: icons.TableOutlined
    },
    {
      id: 'subcategories',
      title: 'Subcategories',
      type: 'item',
      url: '/subcategories',
      icon: icons.TableOutlined
    }
    // {
    //     id: 'login1',
    //     title: 'Login',
    //     type: 'item',
    //     url: '/login',
    //     icon: icons.LoginOutlined,
    //     target: true
    // }
    // {
    //     id: 'register1',
    //     title: 'Register',
    //     type: 'item',
    //     url: '/register',
    //     icon: icons.ProfileOutlined,
    //     target: true
    // }
  ]
};

export default pages;
