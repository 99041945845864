// project import
import pages from './pages';
import dashboard from './dashboard';
import utilities from './utilities';
import support from './support';

// ==============================|| MENU ITEMS ||============================== //
//TODO: support and utilities include or delete from items list
const menuItems = {
  items: [dashboard, pages]
};

export default menuItems;
